import React, { useState, useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useHasMounted, TransitionItem, Loader } from '@luigiclaudio/ga-baseline-ui/helpers';
import { SiteLaunchTemplate } from '@luigiclaudio/ga-baseline-ui/site-launch';
import { ThemeModeContext } from '@luigiclaudio/ga-baseline-ui/theme-provider';
import { ThemeContext } from 'styled-components';
import SiteFooter from '../components/pageLayout/SiteFooter';
import MailingListSubscribeFeature from '../components/pageLayout/MailingListSubscribeFeature';
import IntroCustomBg from '../components/marketing/IntroCustomBg';
import marketingUtils from '../utils/marketingUtils';
import IntroProductItem from '../components/marketing/IntroProductItem';
import brandUtils from '../utils/brandUtils';
import appConfig from '../data/appConfig.json';
import IntroProductsFeatureList from '../components/marketing/IntroProductsFeatureList';
import BrandContext from '../components/context/BrandContext';
import useCreateProductInLocation from '../services/useCreateProductInLocation';

const Index = () => {
    const theme = useContext(ThemeContext);
    const intl = useIntl();
    const hasMounted = useHasMounted();
    const { productOnStage } = useContext(BrandContext);
    const { themeMode } = useContext(ThemeModeContext);
    const [introProductsApi, setIntroProductsApi] = useState();
    const [renderProduct, setRenderProduct] = useState();
    const { countries } = appConfig;

    useCreateProductInLocation();

    let isDarkModeOn = false;

    useEffect(() => {
        if (themeMode === 'darkMode') {
            isDarkModeOn = true;
        }
    }, [themeMode]);

    useEffect(() => {
        const getMarketingProducts = async () => {
            const response = await marketingUtils.introProductsApi();
            setIntroProductsApi(response);
        };
        getMarketingProducts();
    }, []);

    useEffect(() => {
        if (introProductsApi || productOnStage) {
            setRenderProduct(
                productOnStage ||
                    introProductsApi[Math.floor(Math.random() * introProductsApi.length)],
            );
        }
    }, [introProductsApi, productOnStage]);

    const viewportHeight = 69;

    if (!hasMounted) {
        return null;
    }

    if (hasMounted && !renderProduct) {
        return <Loader isFullScreen fullScreenBackgroundColor={theme.site.stage.backgroundColor} />;
    }

    return (
        <SiteLaunchTemplate
            title={intl.formatMessage({ id: 'home.intro.title' })}
            subtitle={intl.formatMessage({ id: 'home.intro.subtitle' })}
            callToActions={[
                {
                    type: 'link',
                    label: intl.formatMessage(
                        {
                            id: 'home.intro.callToAction.viewBrand.label',
                        },
                        {
                            brand: renderProduct?.brand,
                        },
                    ),
                    size: 'lg',
                    btnType: 'outlined',
                    skin: 'primary',
                    path: `/${brandUtils.getPathToBrand(renderProduct?.brand, countries[0])}`,
                },
            ]}
            customBg={
                <IntroCustomBg
                    product={renderProduct}
                    viewportHeight={viewportHeight}
                    themeMode={{ themeMode }}
                />
            }
            viewportHeight={viewportHeight}
            valuePropositionColor={isDarkModeOn ? 'light' : 'dark'}
            alignMainItems="center"
            aside={<IntroProductItem product={renderProduct} />}
            content={
                <TransitionItem inProp appear isFullHeight transitionTimeout={{ enter: 850 }}>
                    <IntroProductsFeatureList />
                    <MailingListSubscribeFeature />
                </TransitionItem>
            }
            footer={<SiteFooter />}
        />
    );
};

export default Index;
