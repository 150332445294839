import React from 'react';
import PropTypes from 'prop-types';
import withSizes from 'react-sizes';
import { isSizes } from '@luigiclaudio/ga-baseline-ui/layout';
import { MailchimpSubscribeFeature } from '@luigiclaudio/ga-mailchimp-signup';
import { useHasMounted } from '@luigiclaudio/ga-baseline-ui/helpers';

const MailingListSubscribeFeature = ({ isXxs, isLg, isXl }) => {
    const hasMounted = useHasMounted();

    let featureContentVerticalSpacing = 18;

    if (isXxs && isLg && isXl) {
        featureContentVerticalSpacing = 14;
    }
    if (!isXxs && isLg && isXl) {
        featureContentVerticalSpacing = 14;
    }
    if (!isXxs && !isLg && isXl) {
        featureContentVerticalSpacing = 18;
    }

    if (!hasMounted) {
        return null;
    }

    return (
        <MailchimpSubscribeFeature
            verticalSpacing={featureContentVerticalSpacing}
            baseUrl={process.env.GATSBY_MAILCHIMP_AUDIENCE_BASE_URL}
            u={process.env.GATSBY_MAILCHIMP_AUDIENCE_U}
            id={process.env.GATSBY_MAILCHIMP_AUDIENCE_ID}
            buttonBtnType="outlined"
            showDivider
        />
    );
};

const mapSizesToProps = ({ width }, { theme }) => {
    return {
        isXxs: isSizes.xxsView({ width }, { theme }),
        isLg: isSizes.lgView({ width }, { theme }),
        isXl: isSizes.xlView({ width }, { theme }),
    };
};

MailingListSubscribeFeature.propTypes = {
    isXxs: PropTypes.bool,
    isLg: PropTypes.bool,
    isXl: PropTypes.bool,
};

MailingListSubscribeFeature.defaultProps = {
    isXxs: false,
    isLg: false,
    isXl: false,
};

export default withSizes(mapSizesToProps)(MailingListSubscribeFeature);
