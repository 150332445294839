const marketingUtils = {
    introProductsApi: async () => {
        const response = await fetch('/api/marketing/intro-category.json').then((res) =>
            res.json(),
        );

        return response;
    },
};

export default marketingUtils;
