import styled from 'styled-components';
import { rem } from 'polished';
import { media } from '@luigiclaudio/ga-baseline-ui/layout';

const IntroProductItemContainer = styled.div`
    height: ${({ itemHeight }) => rem(itemHeight)};
    margin: 8vh auto 5vh;
    width: ${({ itemWidth }) => rem(itemWidth)};

    ${media.lg`
        margin-top: 5vh;
    `}
`;

export default IntroProductItemContainer;
