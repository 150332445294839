import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';
import { ThemeModeContext } from '@luigiclaudio/ga-baseline-ui/theme-provider';
import withSizes from 'react-sizes';
import { isSizes } from '@luigiclaudio/ga-baseline-ui/layout';
import ProductItem from '../../app/ProductItem';
import IntroProductItemContainer from './IntroProductItemContainer';
import productPropTypes from '../../../prop-types/productPropTypes';
import useAllProductUserCache from '../../../services/useAllProductUserCache';
import handleOnHotClick from '../../../utils/handleOnHotClick';

const IntroProductItem = ({ product, isMobile }) => {
    const { themeMode } = useContext(ThemeModeContext);
    const theme = useContext(ThemeContext);

    const {
        allProductsUserFromClient,
        setAllProductsUserFromClient,
        createProduct,
        deleteProduct,
        user,
        pathname,
        client,
    } = useAllProductUserCache();

    const {
        brand,
        title,
        image,
        price,
        priceBeforeSale,
        currency,
        retailer,
        url,
        group,
        currentLocalPrice,
        intlKeywords,
    } = product;
    const itemHeight = theme.site.productItem.oneColumn.height * 1.25;
    const itemWidth = theme.site.productItem.oneColumn.width * (isMobile ? 1.25 : 1.5);

    const isHot = allProductsUserFromClient?.allProducts?.data?.find(
        (currentProduct) => currentProduct.url === url,
    );

    return (
        <IntroProductItemContainer itemHeight={itemHeight} itemWidth={itemWidth}>
            <ProductItem
                brand={brand}
                url={url}
                image={image}
                title={title}
                price={price}
                retailer={retailer}
                group={group}
                priceBeforeSale={priceBeforeSale}
                currency={currency}
                currentLocalPrice={currentLocalPrice}
                intlKeywords={intlKeywords}
                itemWidth={itemWidth}
                itemHeight={itemHeight}
                themeMode={themeMode}
                isHotId={isHot?.id}
                onHotClick={(currentUrl, currentId) =>
                    handleOnHotClick({
                        currentUrl,
                        currentId,
                        currentProduct: product,
                        setAllProductsUserFromClient,
                        allProductsUserFromClient,
                        client,
                        createProduct,
                        deleteProduct,
                        user,
                        pathname,
                    })
                }
            />
        </IntroProductItemContainer>
    );
};

const mapSizesToProps = ({ width }, { theme }) => {
    return {
        isMobile: isSizes.xsView({ width }, { theme }),
    };
};

IntroProductItem.propTypes = {
    product: productPropTypes.product.isRequired,
    isMobile: PropTypes.bool,
};

IntroProductItem.defaultProps = {
    isMobile: false,
};

export default withSizes(mapSizesToProps)(IntroProductItem);
